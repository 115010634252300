export interface LoadingState {
  isLoading: boolean;
  isUpdating: boolean;
  customIsLoading: Record<string, boolean>;
  customIsUpdating: Record<string, boolean>;
}

export const INITIAL_LOADING_STATE: LoadingState = {
  isLoading: false,
  isUpdating: false,
  customIsLoading: {},
  customIsUpdating: {},
};
