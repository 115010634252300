/* eslint-disable max-lines */

export const TEXT = {
  logIn: {
    welcome: 'Welcome',
    form: { title: 'Welcome Back', buttons: { logIn: 'Log In', forgotPassword: 'Forgot password?' } },
  },
  portfolio: {
    buttons: {
      addMainProject: 'Add Main Project',
      onboarding: 'Onboarding',
      userAdmin: 'User Admin',
    },
  },
  onboarding: {
    form: {
      title: 'Onboard Individual Subcontractor',
      buttons: { save: 'Save', cancel: 'Cancel' },
    },
  },
  mainProject: {
    buttons: { addSubProject: 'Add Project', editMainProject: 'Edit' },
    form: {
      title: 'Add Main Project',
      editTitle: 'Edit Main Project',
      buttons: { save: 'Save', cancel: 'Cancel' },
    },
  },
  subProject: {
    form: {
      title: 'Add Project',
      editTitle: 'Edit Project',
      buttons: { save: 'Save', cancel: 'Cancel' },
    },
  },
  navbar: {
    navigation: {
      requisitions: 'Summary',
      change_orders: 'Change Orders',
      administrative: 'Contract Setup',
    },
    beta: 'Beta',
  },
  subNavbar: {
    navigation: {
      project: 'Project',
      main_contract: 'Main Contract',
      sub_main_contract: 'CM + Submissions',
      req_summary: 'Summary',
      manager_req: 'Manager',
      trades_index: 'Trades',
      change_orders_index: 'Change Order Log',
      companies_index: 'Companies',
      users_index: 'Users',
      req_detail: 'Contracts',
      roles_index: 'Permissions',
      technical: 'Technical Layout',
    },
    buttons: {
      settings: 'Settings',
      actions: 'Actions',
      documentation: 'Documentation',
      filter: 'Filter',
    },
  },
  pageSubheader: {
    leftToSchedule: 'Left to schedule:',
    contractValue: 'Contract value:',
  },
  footerTotal: {
    totals: 'Totals',
  },
  reqSummary: {
    summary: 'Summary',
    trades: {
      construction: 'Trades: Construction Totals',
      gr: 'General Requirements Totals',
      pre_construction: 'Trades: Pre-Construction Totals',
      construction_title: 'Trades: Construction',
      gr_title: 'General Requirements',
      pre_construction_title: 'Trades: Pre-Construction',
      unawarded_title: 'Trades: Unawarded',
    },
    terms: {
      gc: 'General Conditions Totals',
      other: 'Other Totals',
      gc_title: 'General Conditions',
      other_title: 'Indirect Work',
    },
    dashboardInfoCard: {
      reqNo: 'REQ #:',
      dueThisPeriod: 'Due This Period',
    },
    submissionsTooltip: `To generate or upload submissions, set
    the submission date in the Summary.`,
    lockAndRollConfirmation: {
      title: "Lock & Roll - This can't be undone",
      text: 'You are about to:\n ● Close the current billing period\n ● Open the next billing period\n\nClick Confirm to proceed',
      buttons: { confirm: 'Confirm' },
    },
  },
  managerReq: {
    documents: 'Documents',
    changeOrders: 'Change Orders',
    baseContract: 'Base Contract',
    buttons: { addLineItem: 'Add Line Item', history: 'History' },
    form: {
      title: 'Add Line Item',
      editTitle: 'Edit Line Item',
      buttons: { save: 'Save', cancel: 'Cancel', saveAndAddAnother: 'Save & Add Another' },
    },
    lineItemAndBalancesModal: {
      title: 'Edit Line Item',
      editLineItemFor: 'Edit line item for',
      editBalancesFor: 'Edit Balances for',
      buttons: { save: 'Save' },
      confirmClose: 'You have unsaved changes.\nWould you like to save or discard them?',
    },
    baseContractTable: {
      youCannotEditLine: 'You can not edit line item in the current version.',
      youCannotEditWhileMovingLineItem: 'You can not edit line item while "Moving line item" is active',
      errorsButtonTooltipData: 'Click to show line items containing only errors',
    },
    submissionsTooltip: `To generate or upload submissions, set
    the submission date in the Summary.`,
    uploadInProgress:
      'Upload is in progress...\n' + 'Please wait until the upload is complete before making any updates.',
    statusNoSubmissionsTooltip: `To enable workflows, set
      the submission date on the Summary.`,
    statusConfirmation: {
      title: 'Are you sure?',
      text: 'Are you sure you want to go back to %1?\nYou will need to re-sign the documents when returning to Final Review.',
      confirmButton: 'OK',
    },
  },
  lineItemTransfers: {
    transferIsNotAvailableError: 'Transfers can only be initiated when current period balances are 0.00.',
    thisPeriodsIsNotZero: 'This period is not 0.00',
    storedMaterialIsNotZero: 'Stored material is not 0.00.',
    retainageThisPeriodIsNotZero: 'Retainage for this period is not 0.00.',
  },
  deleteLineItemConfirmation: {
    title: 'Delete line item',
    text: 'Are you sure you want to delete line item',
    button: {
      delete: 'Delete',
    },
  },
  reqDetail: {
    reqDetail: 'Contracts',
    baseContract: 'Base Contract',
    changeOrders: 'Change Orders',
    form: {
      title: 'Add Line Item',
      editTitle: 'Edit Line Item',
      buttons: { save: 'Save', cancel: 'Cancel', saveAndAddAnother: 'Save & Add Another' },
    },
    lineItemAndBalancesModal: {
      title: 'Edit Line Item',
      titleCO: 'Edit Change Order',
      editLineItemFor: 'Edit line item for',
      editChangeOrderFor: 'Edit change order for',
      editBalancesFor: 'Edit Balances for',
      buttons: { save: 'Save' },
      confirmClose: 'You have unsaved changes.\nWould you like to save or discard them?',
    },
    baseContractTable: {
      youCannotEditLine: 'You can not edit line item in the current version.',
      youCannotEditWhileMovingLineItem: 'You can not edit line item while "Moving line item" is active',
      youCannotEditInTheCurrentStatus: 'You can not edit line item in the current trade status',
      errorsButtonTooltipData: 'Click to show line items containing only errors',
    },
    changeOrdersTable: {
      youCannotEditLine: 'You can not edit change order in the current period.',
      errorsButtonTooltipData: 'Click to show change orders containing only errors',
    },
    submissionsTooltip: `To generate or upload submissions, set
    the submission date on the Summary.`,
    statusNoSubmissionsTooltip: `To enable workflows, set
    the submission date on the Summary.`,
    uploadInProgress:
      'Upload is in progress...\n' + 'Please wait until the upload is complete before making any updates.',
    changeStatusConfirmation: {
      title: 'Are you sure?',
      text: 'Are you sure you want to go back to %1? \nYou will need to re-sign the documents when returning to Final Review. ',
      confirm: 'OK',
    },
  },
  changeOrder: {
    changeOrderLog: 'Change Order Log',
    changeOrderLogSettings: 'Change Order Log Settings',
    buttons: { addChangeOrder: 'Add Change Order', hideTotals: 'Hide Totals', showTotals: 'Show Totals' },
    form: {
      title: 'Add Change Order',
      buttons: { save: 'Save', cancel: 'Cancel' },
    },
    searchInChangeOrders: 'Search in Change Orders',
    hiddenColumn: 'hidden column',
    hiddenColumns: 'hidden columns',
  },
  tableSearchDropdown: {
    searchIn: 'Search in',
    documents: 'documents',
    pictures: 'pictures',
    comments: 'comments',
  },
  companies: {
    companies: 'Companies',
    buttons: { addCompany: 'Add Company' },
    roles: {
      owner: 'Owner',
      construction_manager: 'Construction Manager',
      subcontractor: 'Subcontractor',
      consultant: 'Consultant',
      engineer: 'Engineer',
      architect: 'Architect',
      supplier: 'Supplier',
      vendor: 'Vendor',
      'municipal/government': 'Municipal Government',
    },
    form: {
      assign: {
        title: 'Assign a Company',
        buttons: { save: 'Save', cancel: 'Cancel' },
      },
      add: {
        title: 'Add a Company',
        buttons: { save: 'Save', cancel: 'Cancel' },
      },
    },
  },
  users: {
    users: 'Users',
    searchUser: 'Search user',
    sortBy: 'Sort by:',
    userAdmin: 'User Admin',
  },
  userPopup: {
    currentActive: 'This user last used the platform at %1.',
    currentActiveIsNil: 'The user has not yet used the platform.',
    lastActive: 'Before then, they had previously used it at %1.',
    signInCounts: 'They have made %1 API requests to the platform.',
    currentSignIn: 'Their last sign in was at %1 from %2.',
    signInIsNil: 'This user has not yet signed in.',
    lastSignIn: 'Before then, they signed in at %1 from %2.',
    createdAt: 'Their account was created at %1.',
    resetPasswordSent: 'Their last password reset was sent at %1.',
  },
  userAdminPage: {
    title: 'User Admin',
    subtitleFirst:
      'This page allows superadmins to view all users in the platform, see when they were last active and edit details.',
    subtitleSecond:
      'If a user’s access is disabled, they will be locked out of the platform. Their access can be re-enabled again later.',
    companyField: {
      inputPlaceholder: 'Select company',
      addNew: 'Add a Company',
    },
  },
  spreadsheet: {
    section: 'Section',
    clickToGoToTrade: 'Click to go to trade detail page...',
    hiddenColumn: 'hidden column',
    hiddenColumns: 'hidden columns',
    settings: 'Settings',
    recordsContainErrors: 'records contain errors',
    recordContainErrors: 'record contain errors',
  },
  queryBar: {
    record: 'Record',
    records: 'Records',
    sort: 'Sort',
    filter: 'Filter',
    search: 'Search',
    documents: 'Documents',
    pictures: 'Pictures',
    comments: 'Comments',
    by: 'by',
    asc: 'by A-Z',
    desc: 'by Z-A',
    ascNumerical: 'Ascending',
    descNumerical: 'Descending',
    clearAll: 'Clear all',
    values: {
      filter: 'filter',
      filters: 'filters',
    },
    allValuesModal: {
      title: 'Applied filters',
      noFilters: 'No filters',
      buttons: { save: 'Save', cancel: 'Cancel' },
    },
  },
  spreadsheetWithSections: {
    record: 'Record',
    records: 'Records',
  },
  widgets: {
    documents: 'Documents',
    pictures: 'Pictures',
    comments: 'Comments',
    of: 'of',
    addedBy: 'Added by',
  },
  toasts: {
    info: 'Successful.',
    warning: 'Attention!',
    error: 'Error!',
  },
  trades: {
    amendedValue: 'Amended Contract Value',
    gr: 'GR',
    searchInTrades: 'Search in Trades',
    buttons: { addTrade: 'Add Trade', add: 'Add' },
    form: {
      title: 'Add Trade Contract',
      buttons: { save: 'Save', cancel: 'Cancel' },
    },
    linkCompany: 'Link a company',
  },
  trade: {
    tradeContract: 'Trade Contract',
    confirmation: {
      title: 'Delete trade',
      text: 'Are you sure you want to delete trade',
      button: { delete: 'Delete' },
    },
  },
  history: {
    showAll: 'Show All Drafts',
    history: 'History',
    currentVersion: 'Current Version',
    historyDrafts: 'History Drafts',
    draft: 'Draft',
  },
  buttons: {
    next: 'Next',
    previous: 'Previous',
  },
  attachments: {
    documents: {
      document: 'Document',
      documents: 'Documents',
      addDocument: 'Add Document',
      delete: 'Delete',
      confirmDelete: 'Are you sure you want to delete this document?',
      includedInPayApplication: 'Include in Pay App',
      noFileFound: 'no file found',
      buttons: { submit: 'Submit', cancel: 'Cancel', search: 'Search' },
      searchDocument: 'Search document',
      itemFound: 'item found',
      itemsFound: 'items found',
      openInNewWindow: 'Open in new window',
      wouldYouLikeToSave: 'Would you like to save changes?',
      confirmClose: 'You have unsaved changes in the document.\nWould you like to save or discard them?',
      signed: 'Signed',
      unsigned: 'Unsigned',
      notarized: 'Notarized',
      completed: 'Completed',
      approved: 'Approved',
      draft: 'Draft',
      markAsComplete: 'Mark as Complete',
      markAsIncomplete: 'Mark as Incomplete',
      onCompleteConfirmationText: 'Are you sure you want to mark this document as complete?',
      onIncompleteConfirmationText:
        'Are you sure you want to mark this document as incomplete? It will go back to being a draft.',
      confirmText: 'Confirm',
      disabledCompleteButtonTooltip: 'This document can only be marked as complete during Final Review',
      disabledIncompleteButtonTooltip: 'This document can only be marked as incomplete during Final Review',
      viewOnly: 'View only',
      viewOnlyTooltip:
        'This document is view only and cannot be edited directly.<br/>Changes will appear here after editing documents below.',
      documentAddFormCheckboxTootip: 'Cannot be included in Pay App because the period is already approved.',
    },
    pictures: {
      picture: 'Picture',
      pictures: 'Pictures',
      addPicture: 'Add Picture',
      buttons: { submit: 'Submit', cancel: 'Cancel', add: 'Add' },
      includedInPayApplication: 'Include in Pay App',
      delete: 'Delete',
      confirmDelete: 'Are you sure you want to delete this picture?',
      confirmClose: 'You have unsaved drawings.\nWould you like to save or discard them?',
      'n/a': 'n/a',
      uploadedAt: 'Uploaded at',
      takenAt: 'Taken at',
      by: 'By',
      tags: 'Tags',
      reports: 'Reports',
      separateTagsWithCommas: 'Separate tags with commas',
      of: 'of',
      controls: {
        preview: 'Preview',
        draw: 'Draw',
        color: 'Color',
        thickness: 'Thickness',
        comments: 'Comments',
        zoomOut: 'Zoom Out',
        zoomIn: 'Zoom In',
        undo: 'Undo',
        clear: 'Clear',
        save: 'Save',
      },
      drawingsHasBeenSaved: 'Picture drawings have been saved.',
      thisPictureHasMarkups: 'This picture has markups',
      thisPictureHasComments: 'This picture has comments',
      pictureAddFormCheckboxTootip: 'Cannot be included in Pay App because the period is already approved.',
    },
    comments: {
      anExampleOfReply: 'An example of reply text.',
      noComments: 'No comments',
    },
  },
  uploadFile: {
    browseComputer: 'Browse Computer',
    browseMore: 'Browse more',
    dragAndDropFile: 'Drag-and-drop file',
    popup: {
      cancel: {
        label: 'Cancel',
        tooltip: 'Cancel all uploads',
      },
      progressFiles: '%1 %2 of %3 uploaded',
      title: 'Uploading %1 %2',
    },
  },
  cardConfirmation: {
    cancel: 'Cancel',
    discard: 'Discard',
    save: 'Save',
    delete: 'Delete',
  },
  sidebar: {
    openButton: 'Pay App',
    closeButton: 'Close',
    pictures: {
      file: 'file',
      files: 'files',
      uploadPicture: 'Upload Picture',
      buttons: {
        cancel: 'Cancel',
        submit: 'Submit',
        upload: 'Upload',
        showMore: 'Show more',
      },
      pictureAddFormCheckboxTootip: 'Cannot be included in Pay App because the period is already approved.',
    },
    documents: {
      linkCopied: 'Link copied.',
      unableToCopyLink: 'Unable to copy link.',
      unableToDownloadDocument: 'Unable to download document.',
      backup: 'Additional Backup',
      file: 'file',
      files: 'files',
      of: 'of',
      signed: 'signed',
      uploadDocument: 'Upload Document',
      upload: 'Upload',
      confirmOverride: 'Are you sure you want to override this document?',
      confirmPriorPeriodsGenerate: {
        title: 'Confirm Action',
        text: 'You are viewing a previous draft for this period.\n Are you sure you want to generate the document from this draft?',
        buttons: {
          confirm: 'Yes',
        },
      },
      backupPlaceholderText:
        'You can upload additional backup documents here.\nThese can be included in the Pay App if you wish.',
      placeholderText: {
        optional: 'Document can be optionally added to the Pay App.',
        required: 'Document must be generated to complete the Pay App.',
        helptext: 'Document will be generated on entering Final Review.',
        'helptext-req-summary-gc': 'Document will be generated on approving ManagerREQ.',
        'helptext-req-summary-sub': 'Document will be generated on approving Contracts.',
        'locked-period': 'Document cannot be added because period is locked.',
      },
      buttons: {
        generate: 'Generate',
        upload: 'Upload',
        cancel: 'Cancel',
        submit: 'Submit',
        regenerate: 'Regenerate',
        reupload: 'Reupload',
        template: 'Template',
        complete: 'Mark Complete',
        incomplete: 'Mark Incomplete',
      },
      documentAddFormCheckboxTootip: 'Cannot be included in Pay App because the period is already approved.',
    },
  },
  resourceCompany: {
    setupText:
      'The construction manager assigned to the main contract is the company responsible for managing all trades and the monthly requisition process.',
    setup: 'Setup',
    email: 'Email',
    phone: 'Phone',
    website: 'Website',
    address: 'Address',
    confirmationText: 'Are you sure you want to delete',
    delete: 'Delete',
  },
  ownerCompany: {
    setupText: 'The owner assigned to the project is the company responsible for supervision of the project. ',
    setup: 'Add',
    email: 'Email',
    phone: 'Phone',
    website: 'Website',
    address: 'Address',
    confirmationText: 'Are you sure you want to delete',
    delete: 'Delete',
  },
  mainContract: {
    requiredSubmissions: {
      topText:
        '* Once you add/delete a document here they will automatically appear/disappear in the binder of the Contracts as a',
      requiredSubmissions: 'Required Submission',
      documentViewAlertMessage: {
        title: 'Preview',
        text: 'This document is generated each period and cannot be previewed.',
        button: 'Got it',
      },
    },
    contractCalculations: {
      generalConditions: {
        setupText:
          'General condition terms will update the amount of general conditions calculated on each change order submitted.',
        setup: 'Setup',
        form: {
          title: 'Setup Change Order General Conditions',
          buttons: { save: 'Save', cancel: 'Cancel' },
        },
        name: 'Name',
        applied: 'Applied',
        base: 'Base',
        deadband: 'Deadband',
        included: 'General Conditions is Applied on:',
        bond: 'Bond',
        insurance: 'Insurance',
        fee: 'Fee',
        tax: 'Tax',
        gc: 'GC',
        other: 'Other',
        general_conditions: 'General Conditions',
        change_orders: 'Change Orders',
        confirmationText: 'Are you sure you want to delete Change Order General Conditions?',
        delete: 'Delete',
        noGCText: 'This project does not have general conditions.\nSee',
        contractTerms: 'Contract Terms',
      },
      retainage: {
        setupText:
          'Retainage terms will update the amount of retainage calculated on each line item of the Contracts (unless the line is marked not to include retainage).',
        setup: {
          base_thresholds: 'Base Contract Setup',
          change_orders_thresholds: 'Change Order Setup',
        },
        form: {
          title: {
            base_thresholds: 'Base Contract Setup',
            change_orders_thresholds: 'Change Order Setup',
          },
          buttons: { save: 'Save', cancel: 'Cancel' },
          useTradesComplete: 'Use the trade’s % complete for threshold',
        },
      },
    },
    contractTerms: {
      selectTheTerms: 'Select the terms that apply to this project',
      warningText: 'These changes will deactivate Change Order General Conditions Setup',
      save: 'Save',
      gc: 'GC',
      bond: 'Bond',
      tax: 'Tax',
      fee: 'Fee',
      insurance: 'Insurance',
      other: 'Other',
      selectTheTermsAutobilling: 'Select the terms that require autobilling',
      autobill: 'Autobill',
    },
    managerReqCOAutobilling: {
      selectTheTermsAutobilling: 'Select the terms that require autobilling',
      autobill: 'Autobill',
      save: 'Save',
      gc: 'General Conditions',
      bond: 'Bond',
      tax: 'Tax',
      fee: 'Fee',
      insurance: 'Insurance',
      other: 'Other',
      noTermsText: 'This project does not have any terms.\nSee',
      contractTerms: 'Contract Terms',
    },
  },
  requiredDocument: {
    confirmDelete: 'Are you sure you want to delete this document?',
    delete: 'Delete',
    pinToReqDetail: 'Pin to Contracts',
    uploadTemplate: 'Upload Template',
    addForm: {
      title: 'Upload Template',
      buttons: { save: 'Save', cancel: 'Cancel' },
    },
    Customizable: 'Customizable',
  },
  alert: {
    attention: 'Attention',
    dontShow: 'Don’t show this notice again.',
  },
  error: {
    error: 'Error',
  },
  cardHistoryDropdown: {
    changes: 'Changes',
    at: 'At',
    by: 'By',
  },
  members: {
    addButton: 'Add',
    addMessageMainContract:
      'Team members assigned to the main contract will have access to the contract information and all trade information. A team member’s interactions are limited by their role (viewer or editor), which is set by an editor when the team member is assigned to the main contract.', // $(link-1)Review role descriptions$(link-1)
    addMessageTradeContract:
      'Team members assigned to a trade will have access to the trade information only. A team member’s interactions are limited by their role (viewer or editor), which is set by an editor when the team member is assigned to the trade.', // $(link-1)Review role descriptions$(link-1)
    addMessageProject:
      'Team members assigned to the project will have access to all project contract and trade information. A team member’s interactions are limited by their role (viewer or editor), which is set by an editor when the team member is assigned to the project.', // $(link-1)Review role descriptions$(link-1)
    modal: {
      title: 'Build Team',
      of: 'Build Team',
      assignTeamMembers: 'Assign Team Members',
      inviteNewUser: 'Invite New User',
      buttons: {
        save: 'Save',
        cancel: 'Cancel',
      },
    },
    assignMember: {
      companyWillBeAssigned: 'will be assigned to this project with',
    },
    roles: {
      main_project_viewer: 'Viewer',
      main_project_editor: 'Editor',
    },
    toolbar: {
      add: 'Add',
      showAll: 'Show All',
    },
    invitedMemberCard: {
      cancel: 'Cancel',
      resend: 'Resend',
      delete: 'Delete',
      expired: 'Invite expired. Please delete this invite and create a new one.',
      manage: 'Manage',
      view: 'View',
      pending: 'Invitation pending...',
    },
    memberCard: {
      confirmDeactivate: 'Are you sure you want to remove access for',
      deactivate: 'Remove Access',
    },
  },
  retainage: {
    bar: {
      from: 'From',
      to: 'To',
      calculatedBasedOnTrades: 'Calculated based on trade’s % complete',
    },
    threshold: {
      threshold: 'Threshold',
      retainage: 'Retainage',
      info: 'Once the completion percentage reaches the final threshold, the amount of retainage that is held will no longer change and must be released manually for each trade.',
    },
    addThreshold: 'Add Threshold',
    maxNumberReached: 'You have reached the maximum number of thresholds',
    maxPercentReached: 'You need to lower the threshold from 100% to add more thresholds',
    base_thresholds: 'Base Contract Retainage',
    change_orders_thresholds: 'Change Orders Retainage',
    confirmDelete: 'Are you sure you want to delete Retainage setup?',
    delete: 'Delete',
  },
  searchInput: {
    search: 'Search',
  },
  tradeContract: {
    jobType: {
      union: 'Union',
      'non-union': 'Open Shop',
      other: 'Other',
    },
  },
  tradeLink: {
    clickHereTradeAdmin: 'Click to go to trade admin page...',
    clickHereTradeDetail: 'Click to go to trade detail page...',
  },
  coNumber: {
    created: 'This change order was created in period %1.',
    approved: 'It was approved in period %1.',
    cancelled: 'It was cancelled in period %1.',
  },
  yesNo: {
    true: 'Yes',
    false: 'No',
  },
  costsHistory: {
    history: 'History',
    costsHistory: 'Costs History',
    log: 'Log',
    noData: 'No history data',
  },
  reqPeriod: {
    today: 'Today',
    noDate: 'No date',
    noDates: 'No dates',
    reqPeriods: 'REQ periods',
    showAll: 'See all REQs',
    form: {
      title: 'Setup the dates for',
      endDateTitle: 'Setup the end date for',
      topText: 'To Lock & Roll the REQPeriod you should setup the end date first.',
      bottomText:
        'Once the end date is set and the REQ has been rolled, the REQ is locked to editing. Are you sure you want to continue?',
      buttons: { save: 'Save', cancel: 'Cancel', continue: 'Continue' },
    },
    tooltipData: 'You are in prior period',
  },
  inlineAlert: {
    attention: 'Attention',
  },
  groupAutocomplete: {
    addAGroup: 'Add a group',
  },
  addGroupForm: {
    title: 'Add a group',
    buttons: { save: 'Save', cancel: 'Cancel' },
  },
  uploadDocument: {
    title: 'Import Schedule of Values (SOV)',
    buttons: { submit: 'Submit', cancel: 'Cancel' },
  },
  companyLink: {
    clickHere: 'Click to go to company page...',
  },
  showPageCard: {
    continue: 'Continue',
    chooseCompany: 'A new trade was selected, please choose the associated company',
  },
  dropdown: {
    dropdownValuesIsNotAvailable: 'Dropdown items are not available',
  },
  linkStatus: {
    active: 'Active',
    inactive: 'Inactive',
  },
  inviteInfo: {
    invitationPending: 'Invitation pending...',
    resendInvitation: 'Resend invitation',
  },
  signUp: {
    signUp: 'Sign up',
    welcome: 'Welcome',
    cancel: 'Cancel',
  },
  notificationDropdown: {
    notifications: 'Notifications',
    new: 'New',
    earlier: 'Earlier',
    noData: 'No messages',
  },
  notificationItem: {
    copyLink: 'Copy link',
    download: 'Download',
    open: 'Open',
    downloadingFile: 'Downloading file...',
    linkCopied: 'Link copied.',
  },
  projectMembersModal: {
    title: 'Project Members',
    add: 'Add',
    showAll: 'Show All',
  },
  retainageDecorator: {
    retainageIsNotApplied: 'Retainage is not applied on this line item',
    submissionIsNotOpen: 'Setup the dates for prior period on Summary page',
    periodIsLocked: 'You cannot bill the locked&rolled period',
    switchTooltip: 'When enabled, retainage will be set automatically <br />based on completion percentage.',
    retainageCapped: 'Retainage was capped because the final threshold in settings was reached.',
  },
  uploadProfilePicture: {
    fileSizeIsTooBig: 'Selected image is too big. Maximum image size is 25 MB',
    fileResolutionIsTooSmall: 'Selected image is too small. Minimum image resolution is 72x72 px',
    fileResolutionIsTooBig: 'Selected image is too large. Maximum image resolution is 6000x6000 px',
  },
  cropImageModal: {
    title: 'Add profile picture',
    dragToReposition: 'Drag to reposition',
    buttons: { add: 'Add', cancel: 'Cancel' },
  },
  rolesIndex: {
    usersRolesPermissions: 'User’s Roles & Permissions',
    createUserRole: 'Create User Role',
    levels: {
      project: 'Project',
      main_contract: 'Main Contract',
      trade_contract: 'Trade Contract',
    },
  },
  roleCard: {
    userRole: 'User Role',
    edit: 'Edit',
    view: 'View',
  },
  addRoleForm: {
    createRole: 'Create Role for',
    editRole: 'Edit Role for',
    viewRole: 'View Role for',
    title: 'Title',
    titlePlaceholder: 'Enter role title',
    chooseThePermissions: 'Choose the permissions for this role:',
    buttons: { save: 'Save', cancel: 'Cancel' },
    requiredBy: 'This permission is required by',
  },
  files: {
    success: 'File %1 has been successfully %2.',
    error: 'Error. File %1 was not %2',
  },
  userSettings: {
    yourProfile: 'Your Profile',
    userSettings: 'User Settings',
  },
  changePasswordSendCodeModal: {
    title: 'Change your password',
    text: 'For your account security, first you should verify that it is you who is using the account. For this, we will send you a verification code to your email.',
    buttons: {
      cancel: 'Cancel',
      send: 'Send',
    },
  },
  changePasswordCheckCodeModal: {
    title: 'Change your password',
    text1: 'A verification code was sent to ',
    text2:
      '. The code is valid for 15 minutes.\n' +
      'If you don’t see the email, check junk, spam, social, or other folders.',
    bottomText: 'It may take a minute to receive a code. If you didn’t receive it, click ',
    enterTheCodeHere: 'Enter the code here',
    buttons: {
      cancel: 'Cancel',
      submit: 'Submit',
    },
    resendNewCode: 'Resend a new code',
  },
  createNewPasswordModal: {
    title: 'Create new password',
    topText: 'Your new password must be different from previously used passwords.',
    buttons: {
      save: 'Save',
    },
  },
  forgotYourPassword: {
    backToLogIn: 'Back to log in',
    forgotYourPassword: 'Forgot your password?',
    text: 'Enter your registered email below to receive password reset link:',
    send: 'Send',
  },
  resetPassword: {
    createNewPassword: 'Create new password',
    text: 'Your new password must be different from previously used passwords.',
    save: 'Save',
  },
  emailHasBeenSentModal: {
    title: 'Email has been sent!',
    text:
      'We have sent a password reset instruction to your email.\n' +
      'If you don’t see the email, check junk, spam, social, or other folders.',
    login: 'Log In',
  },
  usePhoneInput: {
    phoneNumberIsTooShort: 'Phone number is too short.',
    phoneNumberIsTooLong: 'Phone number is too long.',
  },
  transferModal: {
    title: 'Make a Transfer',
    originalAmendedContractValue: 'Original Amended Contract Value',
    transferOut: 'Transfer Out',
    remainingBalance: 'Remaining Balance',
    calculationsError: 'You are not allowed to make a transfer. Please, check the calculations.',
    buttons: {
      cancel: 'Cancel',
      transfer: 'Transfer',
    },
    fromSection: {
      from: 'From',
      line: 'Line',
      amendedContractValue: 'Amended Contract Value',
      priorPeriod: 'Prior Period',
      priorPeriodRetainage: 'Prior Period Retainage',
    },
    toSection: {
      to: 'To',
      line: 'Line',
      contract_value: 'Allocate to Contract Value',
      prior_periods: 'Allocate Prior Period',
      retainage_prior_periods: 'Allocate Prior Period Retainage',
      addTransfer: 'Add Transfer',
      youHaveReachedMax: 'You have reached the maximum<br/>number of transfers at a time.',
      selectLineItem: 'Select Line Item',
    },
  },
  lineItemTransferAutoComplete: {
    addNewLineItem: 'Add new line item',
  },
  twoFactorAuthentication: {
    twoFactorAuthentication: 'Two Factor Authentication',
    submit: 'Submit',
    backToLogIn: 'Back to log in',
    text1: 'A verification code was sent to ',
    text2:
      '. The code is valid for 15 minutes.\n' +
      'If you don’t see the email, check junk, spam, social, or other folders.',
    bottomText: 'It may take a minute to receive a code. If you didn’t receive it, click ',
    enterTheCodeHere: 'Enter the code here',
    resendNewCode: 'Resend a new code',
  },
  billingDecorator: {
    autobillingOffTooltip:
      'Autobilling is turned off for this change order.<br/>Click the icon to turn autobilling on.',
    autobillingOnTooltip: 'Autobilling is turned on for this change order.<br/>Click the icon to turn autobilling off.',
    submissionIsNotOpen: 'Setup the dates for prior period on Summary page',
    periodIsLocked: 'You cannot bill the locked&rolled period.',
  },
  attachmentRenderer: {
    noPermissions: "You don't have permissions to view attachments",
    youCannotAddAttachmentsWhileMovingLineItem: 'You can not add attachments while "Moving line item" is active',
  },
  useRequiredDocumentTemplate: {
    of: 'of',
  },
  templateEdit: {
    confirmClose: 'You have unsaved changes.\nWould you like to save or discard them?',
    resetToDefault: 'Reset to default',
    save: 'Save',
  },
  technicalLayout: {
    technicalLayout: 'Technical Layout',
    add: 'Add',
    form: {
      title: 'Add Level',
      buttons: { add: 'Add', cancel: 'Cancel' },
    },
    delete: 'Delete',
    confirmDelete: 'Are you sure you want to delete ',
  },
  levelType: {
    below: 'Levels Below Grade',
    above: 'Levels Above Grade',
    other: 'Other',
  },
  treeSearchDropdown: {
    multipleItems: 'Multiple items',
    search: 'Search',
    dropdownValuesIsNotAvailable: 'Dropdown items are not available',
    all: 'All',
  },
  levelsTreeDropdown: {
    multipleLevels: 'Multiple levels',
    selectAllLevels: 'Select all levels',
    unassignedLines: 'Unassigned lines',
    levels: 'Levels',
    all: 'All',
  },
  reqErrorsModal: {
    topText:
      'You are now at the prior REQperiod. We’ve noticed that the following line items contain calculation mistakes:',
    topTextCO:
      'You are now at the prior REQperiod. We’ve noticed that the following change orders contain calculation mistakes:',
  },
  historicalUploadsErrorsModal: {
    pleaseSelectALineItem: 'Select the line item to view the details.',
    confirmClose: 'You have unsaved changes.\nWould you like to save or discard them?',
    save: 'Save',
    errorTable: {
      recordsContainCriticalMistake: '1 record contain critical mistakes',
      recordsContainCriticalMistakes: '%1 records contain critical mistakes',
      recordsContainNonCriticalMistake: '1 record contain non-critical mistakes',
      recordsContainNonCriticalMistakes: '%1 records contain non-critical mistakes',
    },
    noteHasBeenSaved: 'Note has been saved',
  },
  protectedRoute: {
    lockAndRollInProgress:
      'Lock and roll is in progress...\nPlease wait until the process is complete before making any updates.',
  },
  openSubmissionPeriodModal: {
    title: 'Open submission period',
    priorPeriodTitle: 'Setup dates for prior period',
    endDateTitle: 'Setup the end date for',
    topText: 'To setup the submission period for %1, choose the end date for this period.',
    bottomText: 'Once the submission period is set it cannot be changed. Are you sure you want to continue?',
    buttons: { save: 'Save', cancel: 'Cancel', continue: 'Continue' },
  },
  submitModal: {
    buttons: { confirm: 'Confirm', cancel: 'Cancel' },
  },
  moveLineItem: {
    buttons: { save: 'Save', cancel: 'Cancel' },
  },
  helpTopics: {
    PORTFOLIO: 'Main Portfolio',
    REQ_SUMMARY: 'Summary',
    MANAGER_REQ: 'Manager REQ',
    REQ_DETAIL: 'Trades',
    REQ_DETAIL_SHOW: 'Trade Detail',
    PROJECT_SHOW: 'Project Detail',
    MAIN_CONTRACT_SHOW: 'Main Contract Detail',
    TRADES: 'Trades',
    TRADE_SHOW: 'Trade Administartive Detail',
    COMPANIES: 'Company Detail',
    ROLES: 'Roles',
    TECHNICAL: 'Technical Layout',
    CHANGE_ORDERS: 'Change Order Log',
    CHANGE_ORDERS_SHOW: 'Change Orders Detail',
    USER_ADMIN: 'User Admin',
    USER_SETTINGS: 'User Settings',
    USER_SETTINGS_IN_PROJECT: 'User Settings',
  },
};
