import {
  ProcessProps,
  QueryAction,
  Response,
  QueryDomain,
  Action,
  ResponsePayload,
  AnyLineItem,
  Scenario,
} from 'src/models';
import { sendInfo } from 'src/store/app/app.actions';
import { setCustomIsUpdating } from 'src/store/loading/loading.reducer';
import { processError } from 'src/tools/events.tools';
import { deserialize } from 'src/tools/string.tools';

function processBilling({ dispatch, getService }: ProcessProps) {
  const processUpdateRequest = (action: Action) => () => {
    dispatch(setCustomIsUpdating({ id: 'billing', state: true }));

    getService().request(
      { [QueryDomain.BILLING]: QueryAction.UPDATE, payload: { data: action.payload } },
      (response: Response<string>) => {
        if (response.isOK) {
          const result = deserialize<ResponsePayload<AnyLineItem, Scenario>>(response.payload);

          if (!action.meta?.isUpdate) dispatch(sendInfo('Information has been updated.'));

          if (action.meta?.callback && result) action.meta.callback({ isOK: response.isOK, data: result.data });
        } else {
          if (action.meta?.callback) action.meta.callback({ isOK: false });

          processError({
            activityName: 'Request to update billing/retainage',
            response,
          });

          if (action.meta?.callback) action.meta.callback({ isOK: response.isOK });
        }

        dispatch(setCustomIsUpdating({ id: 'billing', state: false }));
      },
    );
  };

  return {
    processUpdateRequest,
  };
}

export { processBilling };
