import { fromPairs, map, pair } from 'ramda';
import {
  ManagerReqPageTab,
  ManagerReqTableSections,
  ManagerReqTabs,
  ManagerReqTabSections,
} from 'src/models/manager_req.model';
import { FormElementScript, FormScenario, FormShowAs } from 'src/models/form.model';
import { ButtonBaseType } from 'src/models';

export const SECTIONS_BC = {
  [ManagerReqTabs.GC]: ManagerReqTableSections.GC_BASE,
  [ManagerReqTabs.BOND]: ManagerReqTableSections.GC_BOND_BASE,
  [ManagerReqTabs.TAX]: ManagerReqTableSections.TAX_BASE,
  [ManagerReqTabs.FEE]: ManagerReqTableSections.FEE_BASE,
  [ManagerReqTabs.INSURANCE]: ManagerReqTableSections.INSURANCE_BASE,
  [ManagerReqTabs.OTHER]: ManagerReqTableSections.OTHER_BASE,
  [ManagerReqTabs.CONTINGENCY]: ManagerReqTableSections.CONTINGENCY_BASE,
};

export const SECTIONS_CO = {
  [ManagerReqTabs.GC]: ManagerReqTableSections.GC_CHANGE_ORDERS,
  [ManagerReqTabs.BOND]: ManagerReqTableSections.BOND_CHANGE_ORDERS,
  [ManagerReqTabs.TAX]: ManagerReqTableSections.TAX_CHANGE_ORDERS,
  [ManagerReqTabs.FEE]: ManagerReqTableSections.FEE_CHANGE_ORDERS,
  [ManagerReqTabs.INSURANCE]: ManagerReqTableSections.INSURANCE_CHANGE_ORDERS,
  [ManagerReqTabs.OTHER]: undefined,
  [ManagerReqTabs.CONTINGENCY]: undefined,
};

export const MANAGER_REQ_TABS: ManagerReqPageTab[] = [
  {
    id: ManagerReqTabs.GC,
    label: 'General Conditions',
    options: {
      baseContract: ManagerReqTableSections.GC_BASE,
      changeOrders: ManagerReqTableSections.GC_CHANGE_ORDERS,
      totals: ManagerReqTabSections.GC,
    },
  },
  {
    id: ManagerReqTabs.BOND,
    label: 'Bond',
    options: {
      baseContract: ManagerReqTableSections.GC_BOND_BASE,
      changeOrders: ManagerReqTableSections.BOND_CHANGE_ORDERS,
      totals: ManagerReqTabSections.BOND,
    },
  },
  {
    id: ManagerReqTabs.INSURANCE,
    label: 'Insurance',
    options: {
      baseContract: ManagerReqTableSections.INSURANCE_BASE,
      changeOrders: ManagerReqTableSections.INSURANCE_CHANGE_ORDERS,
      totals: ManagerReqTabSections.INSURANCE,
    },
  },
  {
    id: ManagerReqTabs.FEE,
    label: 'Fee',
    options: {
      baseContract: ManagerReqTableSections.FEE_BASE,
      changeOrders: ManagerReqTableSections.FEE_CHANGE_ORDERS,
      totals: ManagerReqTabSections.FEE,
    },
  },
  {
    id: ManagerReqTabs.TAX,
    label: 'Tax',
    options: {
      baseContract: ManagerReqTableSections.TAX_BASE,
      changeOrders: ManagerReqTableSections.TAX_CHANGE_ORDERS,
      totals: ManagerReqTabSections.TAX,
    },
  },
  {
    id: ManagerReqTabs.OTHER,
    label: 'Other',
    options: {
      baseContract: ManagerReqTableSections.OTHER_BASE,
      totals: ManagerReqTabSections.OTHER,
    },
  },
  {
    id: ManagerReqTabs.CONTINGENCY,
    label: 'Contingency',
    options: {
      baseContract: ManagerReqTableSections.CONTINGENCY_BASE,
      totals: ManagerReqTabSections.CONTINGENCY,
    },
  },
];

export const MANAGER_REQ_TABS_BY_TABID = fromPairs(
  map((tab: ManagerReqPageTab) => pair(tab.id, tab), MANAGER_REQ_TABS),
) as Record<ManagerReqTabs, ManagerReqPageTab>;

export const MANAGER_REQ_ADD_LINE_ITEM_FORM_SCENARIO: FormScenario = {
  section: {
    label: 'Section',
    showAs: FormShowAs.DROPDOWN,
    isRequired: true,
    placeholder: 'Select section',
    items: [
      { id: ManagerReqTableSections.GC_BASE, label: 'General Conditions' },
      { id: ManagerReqTableSections.GC_BOND_BASE, label: 'Bond' },
      { id: ManagerReqTableSections.INSURANCE_BASE, label: 'Insurance' },
      { id: ManagerReqTableSections.FEE_BASE, label: 'Fee' },
      { id: ManagerReqTableSections.TAX_BASE, label: 'Tax' },
      { id: ManagerReqTableSections.OTHER_BASE, label: 'Other' },
      { id: ManagerReqTableSections.CONTINGENCY_BASE, label: 'Contingency' },
    ],
  },
  category: {
    label: 'Category',
    showAs: FormShowAs.TEXT,
    placeholder: 'Select category',
    type: 'string',
  },
  csi_code: {
    label: 'CSI number',
    showAs: FormShowAs.CSI_AUTO_COMPLETE,
    placeholder: 'Enter CSI number',
    readKey: 'csi_description',
  },
  description: {
    label: 'Description',
    showAs: FormShowAs.TEXT,
    isRequired: true,
    placeholder: 'Enter description',
    type: 'string',
  },
  levels: {
    label: 'Level',
    showAs: FormShowAs.LEVELS_TREE_DROPDOWN,
    placeholder: 'Select the level',
  },
  group: {
    label: 'Group',
    showAs: FormShowAs.MAIN_GROUP,
    placeholder: 'Select group',
    type: 'string',
    writeKeys: ['group_input'],
  },
  amount: {
    label: 'Amount',
    showAs: FormShowAs.FIN,
    isRequired: true,
    placeholder: 'Enter amount',
  },
  retainage_applied: {
    placeholder: 'Apply retainage',
    showAs: FormShowAs.SWITCH,
    defaultValue: false,
  },
};

export const MANAGER_REQ_EDIT_LINE_ITEM_FORM_SCENARIO: FormScenario = {
  ...MANAGER_REQ_ADD_LINE_ITEM_FORM_SCENARIO,
};

delete MANAGER_REQ_EDIT_LINE_ITEM_FORM_SCENARIO.levels;

export const MANAGER_REQ_ACTIONS_DROPDOWN_ITEMS: ButtonBaseType[] = [
  { id: 'upload_template', label: 'Import Excel SOV' },
  { id: 'download_template', label: 'Export to Excel SOV' },
  { id: 'transfer_log', label: 'Transfer Log' },
  { id: 'save_draft', label: 'Save Draft' },
];

export const MANAGER_REQ_EDIT_LINE_ITEM_BALANCES_FORM_SCENARIO: FormScenario = {
  prior_periods: {
    group: 'row-1',
    label: 'Prior Period',
    showAs: FormShowAs.INLINE_NUMBER,
    type: 'number',
  },
  retainage_prior_periods: {
    group: 'row-1',
    label: 'Retainage: Prior Period',
    showAs: FormShowAs.INLINE_NUMBER,
    type: 'number',
  },
  percentage_completed: {
    group: 'row-1',
    label: '% Complete',
    showAs: FormShowAs.INLINE_NUMBER,
    type: 'number',
  },
  this_period: {
    group: 'row-2',
    label: 'This Period',
    showAs: FormShowAs.INLINE_NUMBER,
    type: 'number',
  },
  retainage_this_period: {
    group: 'row-2',
    label: 'Retainage: This Period',
    showAs: FormShowAs.INLINE_NUMBER,
    type: 'number',
  },
  balance_to_finish: {
    group: 'row-2',
    label: 'Balance to finish',
    showAs: FormShowAs.INLINE_NUMBER,
    type: 'number',
  },
  stored_material: {
    group: 'row-3',
    label: 'Stored Material',
    showAs: FormShowAs.INLINE_NUMBER,
    type: 'number',
  },
  retainage_total: {
    group: 'row-3',
    label: 'Retainage: Total',
    showAs: FormShowAs.INLINE_NUMBER,
    type: 'number',
  },
  due_this_period: {
    group: 'row-3',
    label: 'Due this Period',
    showAs: FormShowAs.INLINE_NUMBER,
    type: 'number',
  },
  total_completed: {
    group: 'row-4',
    flexInGroup: '0 0 calc(33.333% - 1.6rem)',
    label: 'Total Completed',
    showAs: FormShowAs.INLINE_NUMBER,
    type: 'number',
  },
};

export const MANAGER_REQ_SECTIONS = {
  [ManagerReqTabs.GC]: ManagerReqTableSections.GC_BASE,
  [ManagerReqTabs.BOND]: ManagerReqTableSections.GC_BOND_BASE,
  [ManagerReqTabs.TAX]: ManagerReqTableSections.TAX_BASE,
  [ManagerReqTabs.FEE]: ManagerReqTableSections.FEE_BASE,
  [ManagerReqTabs.INSURANCE]: ManagerReqTableSections.INSURANCE_BASE,
  [ManagerReqTabs.OTHER]: ManagerReqTableSections.OTHER_BASE,
  [ManagerReqTabs.CONTINGENCY]: ManagerReqTableSections.CONTINGENCY_BASE,
};

export const NOTE_FIELD: FormElementScript = {
  buttonLabel: 'Add a note',
  placeholder: 'Message...',
  showAs: FormShowAs.INLINE_TEXTAREA_BUTTON,
  autofocus: true,
};
