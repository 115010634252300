import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { keys } from 'ramda';

import { CustomState } from 'src/models/general.model';
import { addOrRemoveKey } from 'src/tools/object.tools';
import { RootState } from '..';
import { INITIAL_LOADING_STATE } from './loading.model';

export const slice = createSlice({
  name: 'loading',
  initialState: INITIAL_LOADING_STATE,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsUpdating: (state, action: PayloadAction<boolean>) => {
      state.isUpdating = action.payload;
    },
    setCustomIsLoading: (state, action: PayloadAction<CustomState>) => {
      state.customIsLoading = addOrRemoveKey(state.customIsLoading, action.payload);
    },
    setCustomIsUpdating: (state, action: PayloadAction<CustomState>) => {
      state.customIsUpdating = addOrRemoveKey(state.customIsUpdating, action.payload);
    },
  },
});

export const getIsLoading = (state: RootState) => state.loading.isLoading;

export const getIsUpdating = (state: RootState) => state.loading.isUpdating;

export const getCustomIsLoading = (state: RootState) => (id: string) => state.loading.customIsLoading[id];

export const getCustomIsUpdating = (state: RootState) => (id: string) => state.loading.customIsUpdating[id];

function getIfCustom(data: Record<string, boolean>): boolean {
  let result = false;

  for (const key of keys(data)) {
    if (data[key]) result = true;
  }

  return result;
}

export const getIsBusy = (state: RootState) =>
  state.loading.isLoading ||
  state.loading.isUpdating ||
  getIfCustom(state.loading.customIsLoading) ||
  getIfCustom(state.loading.customIsUpdating);

export const { setCustomIsLoading, setIsLoading, setIsUpdating, setCustomIsUpdating } = slice.actions;

export default slice.reducer;
